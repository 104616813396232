import { Environments } from './environments.interface';

export const production: Environments = {
	baseUrl: 'https://profile.mrm-etagi.com',
	apiUrl: 'https://profile.mrm-etagi.com/api',

	reportsApiUrl: 'https://new.etagidata.ru/reports',
	reportsEtagiProApiUrl: 'https://etagi.pro/api/dashboard',
	demarkBotApiUrl: 'https://markbot.etagi.pro/api',
	aggregatorsApiUrl: 'https://aggregators-backend.data-etagi.ru',

	leadsMonitorBaseUrl: 'https://leads.mrm-etagi.com',
	leadsMonitorApiUrl: 'https://leads.mrm-etagi.com/api',
	leadsMonitorWsUrl: 'wss://leads.mrm-etagi.com/ws',

	handbooksApiUrl: 'https://handbook.mrm-etagi.com/api',

	chatGptApiUrl: 'https://new.etagidata.ru/mrm/chatgpt',

	budgetsBaseUrl: 'https://bdg.mrm-etagi.com',
	budgetsApiUrl: 'https://bdg.mrm-etagi.com/api',
	budgetsWsUrl: 'wss://bdg.mrm-etagi.com/ws',

	storageApiUrl: 'https://storage.mrm-etagi.com/api',

	settingsApiUrl: 'https://setting.mrm-etagi.com/api',

	contentApiUrl: 'https://content.etagi.pro',

	telegramChatUrl: 'https://t.me/+f6TyNd3ryzxkNzc6',

	notificationsApiUrl: 'https://notifications.mrm-etagi.com',
};
